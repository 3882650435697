import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getProductForUpdateById,
  addOrUpdateProduct,
  updateActivateCoverPhoto,
  dleleteProductImage,
} from '../../../api/shopAPI'
import { ProductAddFormStyled } from './ProductAddFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../../styles/elements/formsStyle'
import {
  SubmitButton,
  RedSubmitButton,
  GreenSubmitButton,
} from '../../../styles/elements/buttons'

import SuccesAlert from '../../alert/SuccesAlert'
import ErrorAlert from '../../alert/ErrorAlert'
import ImageUploader from '../../imagesUploader/ImagesUploader'
import TogleCheckBoxYN from '../../buttons/togleCheckBoxYN/TogleCheckBoxYN'
import SelectOption from '../../selectOption/SelectOption'
import DeleteButton from '../../buttons/delete/DeleteButton'

const cleanFormData = {
  ean_code: '',
  name: '',
  ingredients: '',
  allergens: '',
  aditivi: 0,
  nutritional_declaration: '',
  energetic_value: '',
  frozen_products: 0,
  weight: '',
  units: '',
  price: '',
  currency: '',
  product_category: '',
}

const validation = {
  ean_code: (data) => data.ean_code.length < 6 || isNaN(data.ean_code),
  name: (data) => data.name.length < 2,
  ingredients: (data) => data.ingredients.length < 2,
  allergens: (data) => data.allergens.length < 0,
  // aditivi: (data) => data.aditivi.length < 1 || isNaN(data.aditivi),
  nutritional_declaration: (data) => data.nutritional_declaration.length < 0,
  energetic_value: (data) =>
    data.energetic_value.length < 0 || isNaN(data.energetic_value),
  // frozen_products: (data) =>
  //   data.frozen_products.length < 1 || isNaN(data.frozen_products),
  weight: (data) => data.weight.length < 1 || isNaN(data.weight),
  units: (data) => data.units === '' || data.units === 'default',
  price: (data) => data.price.length < 1 || isNaN(data.price),
  currency: (data) => data.currency === '' || data.currency === 'default',
  product_category: (data) =>
    data.product_category === '' || data.product_category === 'default', // Adjusted
}

function ProductAddForm({ elID, cancel }) {
  const [formData, setFormData] = useState(cleanFormData)
  const [elementId, setElementId] = useState(elID)
  const [product, setProduct] = useState({})
  const [showChangeImage, setShowChangeImage] = useState(null)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const ActivateButtonComponent =
    product.active === 1 ? RedSubmitButton : GreenSubmitButton

  // Fetch the special offer by ID whenever `elID` changes
  useEffect(() => {
    if (elementId) {
      getProductForUpdateById(elementId, (response) => {
        setProduct(response)
      })
    }
  }, [elementId, showChangeImage, showAlert]) // Trigger this effect when `elID` changes

  useEffect(() => {
    if (product && Object.keys(product).length > 0) {
      setFormData({
        ean_code: product.ean_code || '',
        name: product.name || '',
        ingredients: product.ingredients || '',
        allergens: product.allergens || '',
        aditivi: product.aditivi || 0,
        nutritional_declaration: product.nutritional_declaration || '',
        energetic_value: product.energetic_value || '',
        frozen_products: product.frozen_products || 0,
        weight: product.weight || '',
        units: product.units || '',
        price: product.price || '',
        currency: product.currency || '',
        product_category: product.product_category || '',
      })
    }
    document.body.style.overflow = 'hidden'
  }, [product])

  // ----------------
  const handleChange = (e) => {
    const { name, value } = e.target

    // if (name === 'product_category') {
    //   // Reset localitate when judet is changed
    //   const updatedFormData = {
    //     ...formData,
    //     product_category: value,
    //     // Reset localitate
    //   }

    //   // Validate judet and localitate after reset

    //   if (!validation[name](updatedFormData)) {
    //     setErrors(errors.filter((el) => el !== name))
    //   } else {
    //     if (!errors.includes(name)) setErrors([...errors, name])
    //   }

    //   setFormData(updatedFormData)
    // } else {
    // Handle other fields
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }
    setFormData({
      ...formData,
      [name]: value,
    })
    // }
  }

  const handleActivateCoverPhoto = (id, coperta, eanCode) => {
    // Toggle coperta state (ensure it's always 1 or 0)
    const newActive = coperta === 1 ? { coperta: 1 } : { coperta: 0 }

    // console.log(
    //   'Toggling cover photo:',
    //   id,
    //   'Current state:',
    //   coperta,
    //   'New state:',
    //   newActive
    // )

    // Set loading state to true
    setIsLoading(true)

    updateActivateCoverPhoto(
      id,
      newActive,
      eanCode,
      handleActivateCoverPhotoSuccess,
      handleError
    )
  }

  // ----------------------
  function handleActivateCoverPhotoSuccess(response) {
    // console.log('API Response:', response) // Log response to check its structure

    // Check if the response contains the expected data
    if (response && typeof response.coperta !== 'undefined' && response.id) {
      // Update product state with the new coperta value for the specific image
      setProduct((prevProduct) => ({
        ...prevProduct,
        product_images: prevProduct.product_images.map((image) => ({
          ...image,
          coperta: image.id === response.id ? response.coperta : 0, // Set coperta image to response.coperta, others to 0
        })),
      }))
      setAlertMessage('Imaginea a fost setata coperta!')
      setShowAlert(true)
      setIsLoading(false)
    } else {
      console.warn('Unexpected response format:', response)
    }
  }

  // ---------
  const handleSubmit = (event) => {
    event.preventDefault()

    // Set loading state to true
    setIsLoading(true)

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData)
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      addOrUpdateProduct(product.id, formData, handleSuccess, handleError)
    }
  }

  function handleDeleteImage(igID) {
    // setShowAddForm(elID)

    setIsLoading(true)
    dleleteProductImage(igID, handleDeleteImagedSuccess, handleError)
  }

  function handleDeleteImagedSuccess(response) {
    console.log(response)

    setAlertMessage('imaginea a fost stearsa!')
    setShowAlert(true)
    setIsLoading(false)
    // navigate(`/produse`, { state: null })
    // cancel()

    const timer = setTimeout(() => {
      // console.log(elID)
      // cancel()
      // navigate(`/produse`, { state: { elID } })
      // window.location.reload()
      // window.location.href = '/setari'
      setShowAlert(false)
    }, 2100)
    return () => clearTimeout(timer)
  }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)

    // Reset loading state
    setIsLoading(false)
  }

  function handleSuccess(response) {
    // console.log(response.id)
    // alert('Contul a fost creat cu succes!')
    setAlertMessage('Datele au fost salvate cu succes!')
    setShowAlert(true)
    // setFormData(cleanFormData)
    // navigate('/login')

    // Reset loading state
    setIsLoading(false)

    const timer = setTimeout(() => {
      // window.location.reload()
      // window.location.href = '/setari'
      // setShowChangeImage(showChangeImage === product.id ? null : product.id)
      setShowChangeImage(response.id)
      setElementId(response.id)
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {/* <BackToSetings /> */}
      {product ? (
        <>
          <ProductAddFormStyled>
            <div className='specOffFormContainer'>
              <div className='formTitle'>
                <h3>{!elementId ? 'Adauga un produs' : 'Editeaza produs'}</h3>
              </div>
              <div className='cancelButtonContainer'>
                <button
                  className='closeButton'
                  onClick={() => {
                    // setIsOpen(false)
                    window.location.reload()
                    setTimeout(cancel, 300) // Delay cancel to allow fade-out
                    document.body.style.overflow = '' // Re-enable body scroll
                  }}
                >
                  Renunta
                </button>
              </div>
              {/* {product.id && ( */}
              {elementId && (
                <>
                  <div className='changeImageButtonFormContainer'>
                    <RedSubmitButton
                      onClick={() => {
                        setShowChangeImage(
                          showChangeImage === product.id ? null : product.id
                        )
                      }}
                    >
                      Adauga imagini
                    </RedSubmitButton>
                  </div>
                  <div className='imagesContainer'>
                    {showChangeImage === product.id && (
                      <ImageUploader
                        apiName={'uploadProductImages'}
                        id={product.id}
                        formTitle={'Adauga imagini'}
                        oldImage={product.product_images}
                        uploadFolder={'products'}
                        fileType={''}
                        cancel={() => setShowChangeImage(null)}
                        // backUrl={'produse'}
                        backUrl={'produse'}
                        productName={product.name}
                        eanCode={product.ean_code}
                      />
                    )}
                    {product.product_images &&
                    product.product_images.length > 0 ? (
                      ''
                    ) : (
                      <span>
                        Este recomandat sa incarcati minim o imagine a
                        produsului.
                      </span>
                    )}

                    {product.product_images?.map((img, index) => (
                      <div className='imageContainer' key={index}>
                        <div className='imageDeleteButtonContainer'>
                          <DeleteButton
                            onClick={() => handleDeleteImage(img.id)}
                            disabled={isLoading}
                          />
                        </div>
                        <img
                          src={img.image_link}
                          alt={product.name}
                          className='thumbnailImg'
                        />
                        <div className='imageCoverButtonContainer'>
                          <TogleCheckBoxYN
                            label={'Coperta'}
                            inputId={`coperta_${img.id}`} // Unique ID for accessibility
                            inputName={`coperta_${img.id}`}
                            checked={img.coperta === 1 ? 1 : 0}
                            onChange={(value) =>
                              handleActivateCoverPhoto(
                                img.id,
                                value,
                                img.ean_code
                              )
                            }
                          />
                        </div>
                      </div>
                    )) || <p>No images available</p>}
                  </div>
                </>
              )}
              <GeneralFormStyled>
                <form onSubmit={handleSubmit}>
                  <div className='oneField' id='category'>
                    <div>
                      <FieldLabel>
                        Categorie
                        {errors.includes('product_category') ? (
                          <ErrorLabel>Selectează categoria!</ErrorLabel>
                        ) : formData.product_category !== '' &&
                          formData.product_category !== 'default' ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Alege categoria</InfoLabel>
                        )}
                      </FieldLabel>
                      <SelectOption
                        selectName='product_category'
                        selectedID={formData.product_category}
                        onChange={handleChange}
                        defaultText='Categoria'
                        optionKey='id'
                        optionValue='name'
                      />
                    </div>
                  </div>
                  <div className='oneField' id='ean_code'>
                    <div>
                      <FieldLabel>
                        Cod produs
                        {!isNaN(formData.ean_code) &&
                        errors.includes('ean_code') === true ? (
                          <ErrorLabel>Cod produs prea scurt!</ErrorLabel>
                        ) : errors.includes('ean_code') ? (
                          <ErrorLabel>Cod produs incorect</ErrorLabel>
                        ) : formData.ean_code.length >= 6 &&
                          !isNaN(formData.ean_code) ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 6 cifre</InfoLabel>
                        )}
                      </FieldLabel>
                      <input
                        type='text'
                        name='ean_code'
                        value={formData.ean_code}
                        onChange={handleChange}
                        placeholder='Cod pprodus'
                        maxLength={6}
                      />
                    </div>
                  </div>
                  <div className='oneField' id='product_name'>
                    <div>
                      <FieldLabel>
                        Nume produs
                        {errors.includes('name') === true ? (
                          <ErrorLabel>Nume produs prea scurt!</ErrorLabel>
                        ) : formData.name.length >= 2 ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 2 caractere</InfoLabel>
                        )}
                      </FieldLabel>
                      <input
                        type='text'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        placeholder='Nume produs'
                      />
                    </div>
                  </div>
                  <div className='oneField' id='ingredients'>
                    <div>
                      <FieldLabel>
                        Ingrediente
                        {errors.includes('ingredients') === true ? (
                          <ErrorLabel>Textul este prea scurt!</ErrorLabel>
                        ) : formData.ingredients.length >= 2 ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 2 caractere</InfoLabel>
                        )}
                      </FieldLabel>
                      <textarea
                        name='ingredients'
                        value={formData.ingredients}
                        onChange={handleChange}
                        placeholder='Ingrediente'
                        className='textareaShort'
                      />
                    </div>
                  </div>
                  <div className='oneField' id='allergens'>
                    <div>
                      <FieldLabel>
                        Alergeni
                        {errors.includes('allergens') === true ? (
                          <ErrorLabel>prea scurt!</ErrorLabel>
                        ) : formData.allergens.length >= 0 ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 10 caractere</InfoLabel>
                        )}
                      </FieldLabel>
                      <textarea
                        name='allergens'
                        value={formData.allergens}
                        onChange={handleChange}
                        placeholder='Alergeni '
                        className='textareaShort'
                      />
                    </div>
                  </div>
                  <div className='oneField' id='nutritional_declaration'>
                    <div>
                      <FieldLabel>
                        Declaratie nutritionala
                        {errors.includes('nutritional_declaration') === true ? (
                          <ErrorLabel>Nu ai completat!</ErrorLabel>
                        ) : formData.nutritional_declaration.length >= 0 ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 10 caractere</InfoLabel>
                        )}
                      </FieldLabel>
                      <textarea
                        name='nutritional_declaration'
                        value={formData.nutritional_declaration}
                        onChange={handleChange}
                        placeholder='Declaratie nutritionala'
                        className='textareaShort'
                      />
                    </div>
                  </div>
                  <div className='oneField' id='energetic_value'>
                    <div>
                      <FieldLabel>
                        Valoare energetica - Kcal / 100 g
                        {!isNaN(formData.energetic_value) &&
                        errors.includes('energetic_value') === true ? (
                          <ErrorLabel>Valoare prea scurta!</ErrorLabel>
                        ) : errors.includes('energetic_value') ? (
                          <ErrorLabel>Valoare incorecta</ErrorLabel>
                        ) : formData.energetic_value.length >= 0 &&
                          !isNaN(formData.energetic_value) ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 1 cifra</InfoLabel>
                        )}
                      </FieldLabel>
                      <input
                        type='text'
                        name='energetic_value'
                        value={formData.energetic_value}
                        onChange={handleChange}
                        placeholder='Valoare energetica'
                      />
                    </div>
                  </div>
                  <div className='multipleFields' id='weight'>
                    <div>
                      <FieldLabel>
                        Portie
                        {!isNaN(formData.weight) &&
                        errors.includes('weight') === true ? (
                          <ErrorLabel>Valoare prea scurta!</ErrorLabel>
                        ) : errors.includes('weight') ? (
                          <ErrorLabel>Valoare incorecta</ErrorLabel>
                        ) : formData.weight.length >= 1 &&
                          !isNaN(formData.weight) ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 1 cifra</InfoLabel>
                        )}
                      </FieldLabel>
                      <input
                        type='text'
                        name='weight'
                        value={formData.weight}
                        onChange={handleChange}
                        placeholder='Portie'
                      />
                    </div>
                    <div>
                      <FieldLabel>
                        Unitate masura
                        {errors.includes('units') ? (
                          <ErrorLabel>
                            Selectează unitatea de masura!
                          </ErrorLabel>
                        ) : formData.units !== '' &&
                          formData.units !== 'default' ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Alege unitatea de masura</InfoLabel>
                        )}
                      </FieldLabel>
                      <SelectOption
                        selectName='units'
                        selectedID={formData.units}
                        onChange={handleChange}
                        defaultText='UM'
                        optionKey='id'
                        optionValue='name_short'
                      />
                      {/* <SelectFormProductWieghtClasses
                        selectedWieghtID={formData.units}
                        onChange={handleChange}
                      /> */}
                    </div>
                  </div>
                  <div className='multipleFields' id='price'>
                    <div>
                      <FieldLabel>
                        Pret
                        {!isNaN(formData.price) &&
                        errors.includes('price') === true ? (
                          <ErrorLabel>Valoare prea scurta!</ErrorLabel>
                        ) : errors.includes('price') ? (
                          <ErrorLabel>Valoare incorecta</ErrorLabel>
                        ) : formData.price.length >= 1 &&
                          !isNaN(formData.price) ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Minim 1 cifra</InfoLabel>
                        )}
                      </FieldLabel>
                      <input
                        type='text'
                        name='price'
                        value={formData.price}
                        onChange={handleChange}
                        placeholder='Pret'
                      />
                    </div>
                    <div>
                      <FieldLabel>
                        Moneda
                        {errors.includes('currency') ? (
                          <ErrorLabel>Selectează moneda!</ErrorLabel>
                        ) : formData.currency !== '' &&
                          formData.currency !== 'default' ? (
                          <SuccesLabel>&#10004;</SuccesLabel>
                        ) : (
                          <InfoLabel>Alege moneda</InfoLabel>
                        )}
                      </FieldLabel>
                      <SelectOption
                        selectName='currency'
                        selectedID={formData.currency}
                        onChange={handleChange}
                        defaultText='Moneda'
                        optionKey='id'
                        optionValue='currency_short'
                      />
                      {/* <SelectFormProductCurrency
                        selectedCurrencyID={formData.currency.id}
                        onChange={handleChange}
                      /> */}
                    </div>
                  </div>
                  <div className='multipleCheckbox'>
                    <FieldLabel>Contine:</FieldLabel>
                    <div className='checkboxContainer'>
                      <div>
                        <TogleCheckBoxYN
                          label={'Aditivi'}
                          inputId={'aditivi'}
                          inputName={'aditivi'}
                          checked={formData.aditivi === 1 ? 1 : 0}
                          onChange={(value) =>
                            setFormData({ ...formData, aditivi: value })
                          }
                        />
                      </div>
                      <div>
                        <TogleCheckBoxYN
                          label={'Produse congelate'}
                          inputId={'frozen_products'}
                          inputName={'frozen_products'}
                          checked={formData.frozen_products === 1 ? 1 : 0}
                          onChange={(value) =>
                            setFormData({ ...formData, frozen_products: value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='submitContainer'>
                    <SubmitButton disabled={isLoading}>
                      {isLoading
                        ? 'Asteapta! Se incarca...'
                        : !product.title
                        ? 'Salveaza'
                        : 'Modifica datele'}
                    </SubmitButton>
                  </div>
                </form>
              </GeneralFormStyled>
            </div>
          </ProductAddFormStyled>
        </>
      ) : (
        <p>Incarc datele</p>
        // <FadeLoading />
      )}
    </>
  )
}

export default ProductAddForm
