import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { submitOrder } from '../../api/checkout'
import { CheckOutFormStyled } from './CheckOutFormStyled'
import {
  GeneralFormStyled,
  FieldLabel,
  InfoLabel,
  SuccesLabel,
  ErrorLabel,
} from '../../styles/elements/formsStyle'
import SuccesAlert from '../../components/alert/SuccesAlert'
import ErrorAlert from '../../components/alert/ErrorAlert'

function validateComplexEmail(email) {
  const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return complexEmailRegex.test(email)
}

const cleanFormData = {
  nume: '',
  prenume: '',
  telefon: '',
  email: '',
  localitate: '',
  strada: '',
  nrStrada: '',
  bloc: '',
  scara: '',
  ap: '',
  interfon: '',
}

const validation = {
  nume: (nume) => nume.length < 3,
  prenume: (prenume) => prenume.length < 3,
  // telefon: (telefon) => telefon.length < 10,
  telefon: (telefon) => telefon.length < 10 || isNaN(telefon),
  email: (email) => email.length < 6 || !validateComplexEmail(email),
  localitate: (localitate) => localitate.length < 3,
  strada: (strada) => strada.length < 3,
  nrStrada: (nrStrada) => nrStrada.length < 0,
  bloc: (bloc) => bloc.length < 0,
  scara: (scara) => scara.length < 0,
  ap: (ap) => ap.length < 0,
  interfon: (interfon) => interfon.length < 0,
}

function CheckOutForm({ cart, isLoggedIn }) {
  const [userData, setUserData] = useState({})
  const [formData, setFormData] = useState(cleanFormData)
  const [errors, setErrors] = useState([])
  const [responseErrors, setResponseErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'))
    if (storedUserData) {
      setUserData(storedUserData)
      if (isLoggedIn) {
        setFormData({
          nume: storedUserData.first_name || '',
          prenume: storedUserData.last_name || '',
          telefon: storedUserData.phone || '',
          email: storedUserData.email || '',
          localitate: storedUserData.city || '',
          strada: storedUserData.street || '',
          nrStrada: storedUserData.street_number || '',
          bloc: storedUserData.building || '',
          scara: storedUserData.staircase || '',
          ap: storedUserData.apartment || '',
          interfon: storedUserData.intercom || '',
        })
      }
    }
  }, [isLoggedIn])

  const total = {
    total_price: cart.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    ),
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (!validation[name]({ ...formData, [name]: value })) {
      setErrors(errors.filter((el) => el !== name))
    } else {
      if (!errors.includes(name)) setErrors([...errors, name])
    }

    setFormData({
      ...formData,
      [name]: value,
    })
  }
  // const handleChange = (e) => {
  //   if (!validation[e.target.name](e.target.value)) {
  //     setErrors(errors.filter((el) => el !== e.target.name))
  //   }

  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   })
  // }

  function handleSubmit(event) {
    event.preventDefault()

    const validationErrors = Object.keys(validation).filter((el) =>
      validation[el](formData[el])
    )

    setErrors(validationErrors)

    if (validationErrors.length === 0) {
      const orderData = {
        ...formData,
        cart,
        total_price: total.total_price,
        currency: cart[0]?.currency?.currency_short || 'moneda',
      }

      submitOrder(
        orderData,
        handleSuccess,
        // (data) => {
        //   localStorage.removeItem('cart')
        //   alert('Comanda a fost trimisa')
        //   navigate('/')
        // },
        handleError
      )
    }
  }

  // function handleError(error) {
  //   console.error(error)
  // }

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  function handleSuccess(response) {
    console.log(response)
    //   localStorage.removeItem('cart')
    //   alert('Comanda a fost trimisa')
    //   navigate('/')
    setAlertMessage('Comanda a fost transmisa!')
    localStorage.removeItem('cart')
    setShowAlert(true)
    setFormData(cleanFormData)
    // navigate('/login')
    const timer = setTimeout(() => {
      // window.location.reload()
      window.location.href = '/'
    }, 2100)
    return () => clearTimeout(timer)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  return (
    <>
      {showAlert && <SuccesAlert message={alertMessage} />}

      <div>
        {responseErrors.map((message, index) => (
          <ErrorAlert
            key={index}
            message={message}
            onRemove={() => removeError(index)}
          />
        ))}
      </div>
      {total.total_price !== 0 && (
        <CheckOutFormStyled>
          {!isLoggedIn && (
            <div className='registerLink'>
              <Link to={'/register'}>Creeaza cont</Link>
              <span>sau trimite comanda fara cont de client</span>
            </div>
          )}
          <div className='title'>
            <h2>Date livrare</h2>
          </div>
          <GeneralFormStyled>
            <form onSubmit={handleSubmit}>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>
                    Nume
                    {errors.includes('nume') === true ? (
                      <ErrorLabel>Nume prea scurt!</ErrorLabel>
                    ) : formData.nume.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='nume'
                    value={formData.nume}
                    onChange={handleChange}
                    placeholder='Nume'
                  />
                </div>
                <div>
                  <FieldLabel>
                    Prenume
                    {errors.includes('prenume') === true ? (
                      <ErrorLabel>Prenume prea scurt!</ErrorLabel>
                    ) : formData.prenume.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='prenume'
                    value={formData.prenume}
                    onChange={handleChange}
                    placeholder='Prenume'
                  />
                </div>
              </div>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>
                    Telefon
                    {!isNaN(formData.telefon) &&
                    errors.includes('telefon') === true ? (
                      <ErrorLabel>Telefon prea scurt!</ErrorLabel>
                    ) : errors.includes('telefon') ? (
                      <ErrorLabel>Numar incorect</ErrorLabel>
                    ) : formData.telefon.length >= 10 &&
                      !isNaN(formData.telefon) ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 10 cifre</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='telefon'
                    value={formData.telefon}
                    onChange={handleChange}
                    placeholder='Telefon'
                  />
                </div>
                <div>
                  <FieldLabel>
                    Email
                    {errors.includes('email') === true ? (
                      <ErrorLabel>Email invalid!</ErrorLabel>
                    ) : formData.email.length >= 6 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 6 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Email'
                  />
                </div>
              </div>
              <div className='oneField'>
                <FieldLabel>
                  Localitate
                  {errors.includes('localitate') === true ? (
                    <ErrorLabel>Localitatea e prea scurta!</ErrorLabel>
                  ) : formData.localitate.length >= 3 ? (
                    <SuccesLabel>&#10004;</SuccesLabel>
                  ) : (
                    <InfoLabel>Minim 3 caractere</InfoLabel>
                  )}
                </FieldLabel>
                <input
                  type='text'
                  name='localitate'
                  value={formData.localitate}
                  onChange={handleChange}
                  placeholder='Localitate'
                />
              </div>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>
                    Strada
                    {errors.includes('strada') === true ? (
                      <ErrorLabel>Strada e prea scurta!</ErrorLabel>
                    ) : formData.strada.length >= 3 ? (
                      <SuccesLabel>&#10004;</SuccesLabel>
                    ) : (
                      <InfoLabel>Minim 3 caractere</InfoLabel>
                    )}
                  </FieldLabel>
                  <input
                    type='text'
                    name='strada'
                    value={formData.strada}
                    onChange={handleChange}
                    placeholder='Strada'
                  />
                </div>
                <div>
                  <FieldLabel>Nr Strada</FieldLabel>
                  <input
                    type='text'
                    name='nrStrada'
                    value={formData.nrStrada}
                    onChange={handleChange}
                    placeholder='Nr Strada'
                  />
                </div>
              </div>
              <div className='multipleFields'>
                <div>
                  <FieldLabel>Bloc</FieldLabel>
                  <input
                    type='text'
                    name='bloc'
                    value={formData.bloc}
                    onChange={handleChange}
                    placeholder='Bloc'
                  />
                </div>
                <div>
                  <FieldLabel>Scara</FieldLabel>
                  <input
                    type='text'
                    name='scara'
                    value={formData.scara}
                    onChange={handleChange}
                    placeholder='Scara'
                  />
                </div>
                <div>
                  <FieldLabel>Ap</FieldLabel>
                  <input
                    type='text'
                    name='ap'
                    value={formData.ap}
                    onChange={handleChange}
                    placeholder='Ap'
                  />
                </div>
              </div>
              <div className='oneField'>
                <FieldLabel>Nr. interfon</FieldLabel>
                <input
                  type='text'
                  name='interfon'
                  value={formData.interfon}
                  onChange={handleChange}
                  placeholder='Nr. interfon'
                />
              </div>
              <div className='submitContainer'>
                <button className='submitButton'>Trimite comanda</button>
              </div>
            </form>
          </GeneralFormStyled>
        </CheckOutFormStyled>
      )}
    </>
  )
}

export default CheckOutForm
