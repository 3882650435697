// Pagination.js
import React from 'react'
import { PaginationContainer } from './PaginationStyled'
import { useNavigate } from 'react-router-dom'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const navigate = useNavigate()
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }

  return (
    <>
      <PaginationContainer>
        <button
          disabled={currentPage === 1}
          className='PageButton'
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &laquo;
        </button>
        {currentPage > 3 && <span className='dots'>...</span>}
        {Array.from({ length: Math.min(totalPages, 5) }, (_, i) => {
          const page = Math.max(1, currentPage - 2) + i
          if (page <= totalPages) {
            return (
              <button
                key={page}
                // active={page === currentPage}
                className={page === currentPage ? 'ActiveButton' : 'PageButton'}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )
          }
          return null
        })}
        {currentPage < totalPages - 2 && <span className='dots'>...</span>}
        <button
          className='PageButton'
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          &raquo;
        </button>
      </PaginationContainer>
      {/* <div>
        <div>ordoneaza dupa</div>
        <div>rezultate pe pagina</div>
      </div> */}
    </>
  )
}

export default Pagination
