import { apiRest } from './config'

// function getUnitDataHeader(
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .get('/api/unit-data-header')
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }
function getUnitData(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get('/api/unit-data')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateUnitData(
  unitID,
  unitData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/unit-data/${unitID}`, unitData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getAboutUnit(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/about`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAboutUnitActive(
  aboutID,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-about/${aboutID}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAboutUnit(
  unitID,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/update-about/${unitID}`, formData)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateAboutUnitPhoto(
  aboutId,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-about-photo/${aboutId}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// function updateAboutUnitPhoto(
//   unitID,
//   formData,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) {
//   apiRest
//     .post(`/api/update-about-photo/${unitID}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
//       },
//     })
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

// update-about/{id}/{uploadFolder}

function getAllSpecOffer(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-special-offers`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getFirstSpecOffer(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/first-special-offer`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getSpecOfferById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/special-offer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateSpecOffersActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-special-offer/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateSpecOffer(
  unitID,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!unitID) {
    request = apiRest.post('/api/add-new-special-offer', formData)
  } else {
    request = apiRest.put(`/api/update-special-offer/${unitID}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateSpecialOfferPhoto(
  id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-special-offer-photo/${id}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// const deleteSpecialOffer = (
//   id,
//   successCallback,
//   errorCallback = (err) => console.log(err)
// ) => {
//   apiRest
//     .delete(`/api/special-offer/${id}`)
//     .then(({ data }) => {
//       successCallback(data)
//     })
//     .catch((err) => errorCallback(err))
// }

function deleteSpecialOffer(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/special-offer/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- facility -----
function getFacilitys(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/all-facilitys`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getFacilityById(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function addOrUpdateFacility(
  id,
  formData,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  let request

  if (!id) {
    request = apiRest.post('/api/add-new-facility', formData)
  } else {
    request = apiRest.put(`/api/update-facility/${id}`, formData)
  }

  request
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateFacilityActive(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/activate-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateFacilityPrincipal(
  id,
  active,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .put(`/api/principal-facility/${id}`, active)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function updateFacilityIcon(
  id,
  uploadFolder,
  formData, // Add formData as an argument to pass the image and name
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .post(`/api/update-facility-icon/${id}/${uploadFolder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the header to handle file uploads
      },
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function deleteFacility(
  id,
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .delete(`/api/facility/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

// ----- working hours -----
function getWorkingHours(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/working-program`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getDeliveryConditions(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/delivery-conditions`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getDeliveryPrices(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/delivery-prices`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

function getUnitLocalization(
  successCallback,
  errorCallback = (err) => console.log(err)
) {
  apiRest
    .get(`/where-we-are`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch((err) => errorCallback(err))
}

export {
  // getUnitDataHeader,
  getUnitData,
  updateUnitData,
  getAboutUnit,
  updateAboutUnitActive,
  updateAboutUnit,
  updateAboutUnitPhoto,
  // ---
  getAllSpecOffer,
  getFirstSpecOffer,
  getSpecOfferById,
  updateSpecOffersActive,
  updateSpecOffer,
  updateSpecialOfferPhoto,
  deleteSpecialOffer,
  // ---
  getFacilitys,
  getFacilityById,
  addOrUpdateFacility,
  updateFacilityActive,
  updateFacilityPrincipal,
  updateFacilityIcon,
  deleteFacility,
  // ---
  getWorkingHours,
  // ---
  getDeliveryConditions,
  // ---
  getDeliveryPrices,
  // ---
  getUnitLocalization,
  // ---
}
