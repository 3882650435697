import styled from 'styled-components'
import { desktop, tablet, mobile } from '../setings/mediaScreens'

export const SubmitButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
  padding: 10px 25px 10px 25px;
  border-radius: 50px;
  border: solid #ccc 2px;
  background-color: #6c6c6c;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #474747;
    border: solid #474747 2px;
  }
  ${mobile`
    margin: 10px 0;
    width: 80%;
    `}
`
export const SubmitButtonSmall = styled.button`
  width: 200px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 5px 0;
  margin-right: 10px;
  padding: 5px 25px 5px 25px;
  border-radius: 5px;
  border: solid #ccc 2px;
  background-color: #6c6c6c;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #474747;
    border: solid #474747 2px;
  }
  ${mobile`
    // margin: 10px 0px 10px 0;
    width: 100%;
    `}
`
export const RedSubmitButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
  padding: 10px 25px 10px 25px;
  border-radius: 50px;
  border: solid #ff2727 2px;
  background-color: #ba2525;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #711717;
    border: solid #ba2525 2px;
  }
  ${mobile`
    margin: 10px 0;
    width: 80%;
  `}
`
export const RedSubmitButtonSmall = styled.button`
  width: 200px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 5px 0;
  padding: 5px 25px 5px 25px;
  border-radius: 5px;
  border: solid #ff2727 2px;
  background-color: #ba2525;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #711717;
    border: solid #ba2525 2px;
  }
  ${mobile`
    // margin: 10px 0;
    width: 100%;
  `}
`
export const GreenSubmitButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
  padding: 10px 25px;
  border-radius: 50px;
  border: solid #28a745 2px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #218838;
    border: solid #218838 2px;
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    margin: 10px 0;
    width: 80%;
  }
`
export const GreenSubmitButtonSmall = styled.button`
  width: 200px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 5px 0;
  padding: 5px 25px;
  border-radius: 5px;
  border: solid #28a745 2px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #218838;
    border: solid #218838 2px;
  }
  ${mobile`
    // margin: 10px 0;
    width: 100%;
  `}
`
