function formatDate(dateString) {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

function formatDateHours(dateString) {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${day}.${month}.${year} - ${hours}:${minutes}`
}

function formatHours(dateString) {
  const date = new Date(dateString)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

function storedTimeDifference(timeStart, timeEnd) {
  // Split the time strings into hours and minutes
  const [startHours, startMinutes] = timeStart.split(':').map(Number)
  const [endHours, endMinutes] = timeEnd.split(':').map(Number)

  // Calculate the difference in hours and minutes
  let diffHours = endHours - startHours
  let diffMinutes = endMinutes - startMinutes

  // Adjust for negative minutes
  if (diffMinutes < 0) {
    diffMinutes += 60
    diffHours -= 1
  }

  // Format hours
  const formattedHours = String(Math.abs(diffHours))
  const hourPart =
    diffHours > 1
      ? `${formattedHours} ore si`
      : diffHours === 1
      ? `${formattedHours} ora si`
      : ''

  // Format minutes
  const formattedMinutes = String(Math.abs(diffMinutes))
  const minutePart =
    formattedMinutes < 2
      ? `${formattedMinutes} minut`
      : `${formattedMinutes} minute`

  // Combine results
  return `${hourPart} ${minutePart}`.trim()
}

export { formatDate, formatDateHours, formatHours, storedTimeDifference }
