import React, { useEffect, useState } from 'react'
import { OrderdProductsStyled } from './OrderdProductsStyled'
import { getCustomerOderedProducts } from '../../../api/orderedProducts'
import FadeLoading from '../../../components/loaders/fadeLoading/FadeLoading'
import { getCustomerByID } from '../../../api/customers'
import ErrorDisplay from '../../../components/alert/ErrorDisplay'
import ChangeOrderStatus from '../../../components/changeOrderStatus/ChangeOrderStatus'
import OrderCustomerData from '../../../components/orderCustomerData/OrderCustomerData'

function OrderdProducts({
  cancel,
  orderId,
  totalOrderPrice,
  currency,
  orderStatus,
  customerID,
}) {
  const [customerData, setCustomerData] = useState({})
  const [productList, setProductList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [responseErrors, setResponseErrors] = useState([])

  const formattedPrice = Number(totalOrderPrice).toFixed(2)

  useEffect(() => {
    getCustomerByID(
      customerID,
      (response) => {
        setCustomerData(response)
        setResponseErrors([])
      },
      (error) => {
        handleError(error)
      }
    )
    if (customerData) {
      getCustomerOderedProducts(
        orderId,
        (response) => {
          setProductList(response)
          setIsOpen(true) // Show component after data is loaded
          document.body.style.overflow = 'hidden' // Disable body scroll
        },
        (error) => {
          // console.error('Nu am reusit sa incarc produsele:', error)
          handleError(error)
        }
      )
    }

    return () => {
      setIsOpen(false) // Hide component when unmounting
      document.body.style.overflow = '' // Re-enable body scroll when component is unmounted
    }
  }, [orderId])

  function handleError(error) {
    console.error(error)

    // Parse the error response
    const errorResponse = error.response.data
    const parsedErrors = Object.values(errorResponse).flat()

    // Update the state with the parsed errors
    setResponseErrors(parsedErrors)
  }

  // Function to remove an error message from the state
  const removeError = (index) => {
    setResponseErrors(responseErrors.filter((_, i) => i !== index))
  }

  // console.log(customerData)

  return (
    <>
      {responseErrors.map((message, index) => (
        <ErrorDisplay
          key={index}
          message={message}
          onRemove={() => removeError(index)}
        />
      ))}

      <OrderdProductsStyled className={isOpen ? 'isOpen' : ''}>
        <div className='cancelButton'>
          <button
            className='closeOrder'
            onClick={() => {
              setIsOpen(false)
              setTimeout(cancel, 300) // Delay cancel to allow fade-out
              document.body.style.overflow = '' // Re-enable body scroll
            }}
          >
            Inchide
          </button>
        </div>
        <div
          className={
            orderStatus === 1
              ? 'OrderNumber recivedO'
              : orderStatus === 2
              ? 'OrderNumber inPreparationO'
              : orderStatus === 5
              ? 'OrderNumber canceldO'
              : orderStatus === 3
              ? 'OrderNumber finishedO'
              : ''
          }
        >
          Comanda {orderId}
        </div>
        <div className='totalOrderPrice'>
          Total: {formattedPrice} {currency}
        </div>
        <ChangeOrderStatus orderStatus={orderStatus} orderId={orderId} />
        {/* <div className='changeOrderStatus'>
          <div className='title'>
            <h4>Schimba statusul comenzii</h4>
          </div>
          <div className='buttons'>
            {orderStatus !== 2 && (
              <button
                className='prepareOrder'
                onClick={() => {
                  setIsOpen(false)
                  setTimeout(cancel, 300) // Delay cancel to allow fade-out
                  document.body.style.overflow = '' // Re-enable body scroll
                }}
              >
                In preparare
              </button>
            )}
            {orderStatus === 2 && (
              <button className='finishOrder'>Finalizeaza</button>
            )}
            {orderStatus === 1 && (
              <button className='cancelOrder'>Anuleaza</button>
            )}
          </div>
        </div> */}
        <OrderCustomerData customerData={customerData} />

        {productList.length > 0 ? (
          <div className='productsContainer' id='orders'>
            <div className='col-12'>
              <h2>Produse:</h2>
            </div>
            {productList.map((el, index) => (
              <React.Fragment key={index}>
                <div className='orderProductsList'>
                  <div className='productImage'>
                    <img
                      src={
                        el.product.cover_image.image_link
                          ? el.product.cover_image.image_link
                          : 'https://www.aroma-pasiunii.intorsura.ro/media/icons/pizza.png'
                      }
                      alt='Aroma Pasiunii'
                    />
                  </div>
                  <div className='productData'>
                    <div className='productName'>
                      <h3>{el.product.name}</h3>
                    </div>
                    <div className='productDetails'>
                      <span>Portie:</span> {el.product.weight}{' '}
                      {el.product.weight_classes.name_short}
                    </div>
                    <div className='productDetails'>
                      <span>Pret:</span> {el.product.price} {el.currency}
                    </div>
                    <div className='productQuantity'>
                      <span>Cantitate:</span>
                      <span className='quantity'>{el.quantity}</span>{' '}
                      <span>buc</span>
                    </div>
                  </div>
                  <div className='orderPrice'>
                    <span>Subtotal: </span>
                    <h3>
                      {(el.quantity * el.product.price).toFixed(2)}
                      <span> {el.currency}</span>
                    </h3>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <h2>Nu exista produse in comanda!</h2>
        )}
      </OrderdProductsStyled>
    </>
  )
}

export default OrderdProducts
