import React, { useState, useEffect } from 'react'
import { DashboardStyled } from './DashboardStyled'
import AdminData from '../../views/adminDashboard/adminData/AdminData'
import AllOrdersHistory from '../../views/adminDashboard/orderHistory/AllOrdersHistory'
import capitalizeFirstLetter from '../../functions/capinalizeFirstLetter'
import AdminGreeting from '../../views/adminDashboard/adminGreeting/AdminGreeting'
import TodayOrdersHistory from '../../views/adminDashboard/orderHistory/todayOrders/TodayOrdersHistory'
import AdminDashboardMenu from '../../views/adminDashboard/adminDasboardMenu/AdminDashboardMenu'

function Dashboard() {
  return (
    <>
      <DashboardStyled>
        <AdminGreeting />
        <AdminDashboardMenu />
        {/* <AdminData /> */}
        {/* <AllOrdersHistory /> */}
        {/* <TodayOrdersHistory /> */}
      </DashboardStyled>
    </>
  )
}

export default Dashboard
