import styled from 'styled-components'
import { COLORS, addUserColors, formColors } from '../setings/colors'
import { desktop, tablet, mobile } from '../setings/mediaScreens'

export const GeneralFormStyled = styled.div`
  width: 100%;
  margin-top: 30px;
  /* background-color: grey; */
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 800px;
    /* background-color: grey; */

    input {
      width: 100%;
      height: 50px;
      margin-bottom: 15px;
      font-size: 1.2rem;
      font-weight: bold;
      /* text-transform: capitalize; */
    }

    select {
      width: 100%;
      height: 50px;
      margin-bottom: 15px;
      font-size: 1.2rem;
      font-weight: bold;
      /* text-transform: capitalize; */
    }

    textarea {
      width: 100%;
      height: 300px;
      margin-bottom: 15px;
      font-size: 1rem;
      /* font-weight: bold; */
      /* text-transform: capitalize; */
    }

    .textareaShort {
      width: 100%;
      height: 100px;
      margin-bottom: 15px;
      font-size: 1rem;
      /* font-weight: bold; */
      /* text-transform: capitalize; */
    }

    .oneField {
      width: 100%;
      div {
        width: 100%;
        margin-right: 5px;
        /* background-color: grey; */
      }
    }

    .multipleFields {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      /* background-color: grey; */
      ${desktop`
        flex-direction: row;
        `}
      ${tablet`
        flex-direction: row;
        `}
        ${mobile`
        flex-direction: column;
        `}

        div {
        width: 100%;
        margin-right: 5px;
        /* background-color: grey; */
      }
    }

    .multipleCheckbox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-bottom: 10px;
      /* background-color: grey; */
      ${desktop`
        flex-direction: column;
        `}
      ${tablet`
        flex-direction: column;
        `}
        ${mobile`
        flex-direction: column;
        `}
      .checkboxContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        /* background-color: grey; */

        ${mobile`
        flex-direction: column;
        `}
        div {
          /* width: 100%; */
          margin-right: 10px;
          /* background-color: grey; */
        }
      }
    }

    /* .imageUploadFields {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
  
      ${desktop`
        flex-direction: row;
        `}
      ${tablet`
        flex-direction: row;
        `}
        ${mobile`
        flex-direction: column;
        `}

        div {
        width: 50%;
        margin-right: 5px;

      }
    } */

    .submitContainer {
      margin-bottom: 25px;
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 15px 0 15px 0;
      /* border-bottom: solid #fff 3px; */
      /* background-color: red; */
      ${mobile`
         padding: 15px 0 10px 0;
      `}
      .submitButton {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-top: 25px;
        padding: 10px 25px 10px 25px;
        border-radius: 50px;
        border: solid #ccc 2px;
        background-color: #6c6c6c;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #474747;
          border: solid #474747 2px;
        }
        ${mobile`
          margin: 10px 0;
          width: 80%;
          `}
      }
      .redSubmitButton {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-top: 25px;
        padding: 10px 25px 10px 25px;
        border-radius: 50px;
        border: solid #ff2727 2px;
        background-color: #ba2525;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #711717;
          border: solid #ba2525 2px;
        }
        ${mobile`
          margin: 10px 0;
          width: 80%;
          `}
      }
    }
  }
`
export const FieldLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  /* margin-right: 10px; */
  width: 100%;
`

export const CheckBoxYN = styled.div`
  input[type='checkbox'] {
    visibility: hidden;
    display: none;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  .rocker {
    display: inline-block;
    position: relative;
    font-size: 0.5em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #888;
    width: 7em;
    height: 4em;
    overflow: hidden;
    border-bottom: 0.5em solid #eee;
  }

  .rocker-small {
    font-size: 0.75em;
  }

  .rocker::before {
    content: '';
    position: absolute;
    top: 0.5em;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #999;
    border: 0.5em solid #eee;
    border-bottom: 0;
  }

  .switch-left,
  .switch-right {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    width: 3em;
    transition: 0.2s;
    user-select: none;
  }

  .switch-left {
    height: 2.4em;
    width: 2.75em;
    left: 0.85em;
    bottom: 0.4em;
    background-color: #ddd;
    transform: rotate(15deg) skewX(15deg);
  }

  .switch-right {
    right: 0.5em;
    bottom: 0;
    background-color: #bd5757;
    color: #fff;
  }

  .switch-left::before,
  .switch-right::before {
    content: '';
    position: absolute;
    width: 0.4em;
    height: 2.45em;
    bottom: -0.45em;
    background-color: #ccc;
    transform: skewY(-65deg);
  }

  .switch-left::before {
    left: -0.4em;
  }

  .switch-right::before {
    right: -0.375em;
    background-color: transparent;
    transform: skewY(65deg);
  }

  input:checked + .switch-left {
    background-color: #0084d0;
    color: #fff;
    bottom: 0px;
    left: 0.5em;
    height: 2.5em;
    width: 3em;
    transform: rotate(0deg) skewX(0deg);
  }

  input:checked + .switch-left::before {
    background-color: transparent;
    width: 3.0833em;
  }

  input:checked + .switch-left + .switch-right {
    background-color: #ddd;
    color: #888;
    bottom: 0.4em;
    right: 0.8em;
    height: 2.4em;
    width: 2.75em;
    transform: rotate(-15deg) skewX(-15deg);
  }

  input:checked + .switch-left + .switch-right::before {
    background-color: #ccc;
  }

  /* Keyboard Users */
  input:focus + .switch-left {
    color: #333;
  }

  input:checked:focus + .switch-left {
    color: #fff;
  }

  input:focus + .switch-left + .switch-right {
    color: #fff;
  }

  input:checked:focus + .switch-left + .switch-right {
    color: #333;
  }
`
export const InfoLabel = styled.span`
  display: inline-block;
  margin-bottom: 0.1em;
  margin-left: 5px;
  padding: 0 1em;
  /* color: ${addUserColors.errorTextColor}; */
  /* color: #fff; */
  font-size: 0.8rem;
`
export const SuccesLabel = styled.span`
  display: inline-block;
  margin-bottom: 0.1em;
  margin-left: 1px;
  padding: 0 1em;
  /* padding: 0 0.1em; */
  color: green;
  font-size: 0.8rem;
  /* width: 100%; */
`
export const ErrorLabel = styled.span`
  display: inline-block;
  background-color: red;
  margin-bottom: 0.1em;
  margin-left: 5px;
  padding: 0.2em 1em;
  color: #fff;
  font-size: 0.8rem;
  /* width: 100%; */
`
export const ExtraLinksContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  ${mobile`
  flex-direction: column;
  justify-content: center;
  `}

  a {
    border-radius: 1em;
    /* outline: none;
    border: none; */
    margin: 0.2em 0;
    background-color: ${formColors.butonColor};
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    width: auto;
    padding: 0.4em 0.9em;
    border: solid transparent 1px;
    color: #000;
    &:hover {
      background-color: ${formColors.butonHoverColor};
      border: solid ${formColors.borderColor} 1px;
      color: ${formColors.textHoverColor};
    }
    ${mobile`
          margin: 10px 0;
          width: 80%;
          `}
  }
`
export const CancelButton = styled.div`
  margin-bottom: 25px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 0 5px 0;
  /* border-bottom: solid #fff 3px; */
  /* border: none;
  background-color: transparent; */
  .cancel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-top: 25px;
    padding: 10px 25px 10px 25px;
    border-radius: 50px;
    border: solid #ccc 2px;
    background-color: #6c6c6c;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #474747;
      border: solid #474747 2px;
    }
    ${mobile`
          margin: 10px 0;
          width: 80%;
          `}
  }
`
