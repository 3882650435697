import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../../styles/setings/mediaScreens'
import { COLORS } from '../../../styles/setings/colors'

export const OrderdProductsStyled = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border: solid ${COLORS.colorAboutBorder} 1px;
  border-radius: 10px;
  padding: 15px 0;
  /* background-color: #000000ef; */
  background-color: #000000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  &.isOpen {
    opacity: 1;
    visibility: visible;
  }

  ${desktop`
    padding: 5px 10px;
  `}
  ${tablet`
  `}
  ${mobile`
  `}

  .cancelButton {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0 5px 0;

    .closeOrder {
      width: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      margin-top: 25px;
      padding: 10px 25px 10px 25px;
      border-radius: 50px;
      border: solid #ccc 2px;
      background-color: #6c6c6c;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #474747;
        border: solid #474747 2px;
      }

      ${mobile`
          margin: 10px 0;
          width: 80%;
      `}
    }
  }

  .OrderNumber {
    display: inline-flex;
    /* text-decoration: underline; */
    text-decoration-thickness: 4px;
    /* text-decoration-color: ${COLORS.colorTextUnderlineDefault}; */
    text-align: center;
    margin: 10px 0;
    /* background-color: #700000; */
    padding: 15px 0;

    ${desktop`
        align-items: center;
        justify-content: center;    
        font-size: 1.5rem;
        font-weight: bold;
      `}

    ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `} 
      
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `};
  }

  .recivedO {
    background-color: #0f2e5d;
    color: #fff;
    text-align: center;
  }
  .inPreparationO {
    background-color: #d2521b;
    color: #fff;
    text-align: center;
  }
  .finishedO {
    background-color: #005816;
    color: #fff;
    text-align: center;
  }
  .canceldO {
    background-color: #bb0101;
    color: #fff;
    text-align: center;
  }

  h2 {
    display: flex;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: ${COLORS.colorTextUnderlineDefault};
    text-align: center;
    /* background-color: #700000; */
    padding: 5px 0;

    ${desktop`
        align-items: flex-end;
        justify-content: flex-start;    
        font-size: 1.5rem;
        font-weight: bold;
      `}
    ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `}
      ${mobile`
          align-items: center;
          justify-content: center;    
          width: 100%;
          font-size: 1.5rem;
          font-weight: bold;
      `}
  }

  .totalOrderPrice {
    display: inline-flex;
    /* text-decoration: underline; */
    text-decoration-thickness: 4px;
    /* text-decoration-color: ${COLORS.colorTextUnderlineDefault}; */
    text-align: center;
    margin: 10px 0;
    background-color: #700000;
    padding: 15px 0;
    color: #fff;
    ${desktop`
        align-items: center;
        justify-content: center;    
        font-size: 1.5rem;
        font-weight: bold;
      `}

    ${tablet`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `} 
      
    ${mobile`
        align-items: center;
        justify-content: center;    
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
      `};
  }

  .productsContainer {
    .orderProductsList {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      background-color: #252525;
      width: 100%;
      margin-top: 10px;

      ${mobile`
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      `}

      .productImage {
        display: flex;
        justify-content: center;
        align-items: center;

        ${mobile`
          display: none;
        `}

        img {
          height: 120px;
          margin-left: 5px;

          ${mobile`
            height: 100%;
            width: 100%;
          `}
        }
      }
    }

    .productData {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      height: 150px;
      padding: 5px 15px;

      ${mobile`
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
      `}

      span {
        font-size: 1rem;
        font-weight: bold;
        margin-right: 5px;
      }

      .productName {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        font-size: 1.3rem;
        font-weight: bold;
        width: 100%;
        height: 1.5rem;
        color: #fff;

        ${mobile`
          justify-content: center;
        `}

        h3 {
          display: inline-flex;
          width: 100%;
          font-size: 1em;
          font-weight: bold;

          ${desktop`
          `}
          ${tablet`
          `}
          ${mobile`
          `}
        }
      }

      .productDetails {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        margin: 3px 0;
        width: 100%;
      }
      .productQuantity {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 3px 0;
        width: 100%;
        .quantity {
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid #fff 2px;
          border-radius: 10px;
          min-width: 50px;
          padding: 5px 0;
          font-size: 2.3em;
          font-weight: bold;
          /* text-decoration: underline; */
          color: #fff;
        }
      }
    }

    .orderPrice {
      display: flex;
      flex-direction: column;
      width: 270px;
      background-color: #404040;

      ${mobile`
        width: 100%;
        flex-direction: row;
        justify-content: center;
      `}

      span {
        font-size: 1em;
        font-weight: bold;
        margin: 0 5px;
      }

      h3 {
        font-size: 1.3em;
        font-weight: bold;

        ${desktop`
        `}
        ${tablet`
        `}
        ${mobile`
        `}

        span {
          font-size: 1rem;
          font-weight: bold;
          margin: 0 5px;
        }
      }
    }
  }
`
